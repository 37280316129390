import { type EditorSDK } from '@wix/editor-platform-sdk-types';
import type { FlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { queryForms } from '@wix/ambassador-forms-v4-form/http';
import { ValidationFormat } from '@wix/ambassador-forms-v4-form/types';
import { NAMESPACE } from '../constants/namespace';
import { provisionApp } from '@wix/business-manager-api';

const SCHEDULING_FORM_FIELDS_APP_DEF_ID =
  '3e4e322b-6d9b-4da0-9f53-e22c4a44a49e';

async function isSchedulingInstalled(sdk: EditorSDK): Promise<boolean> {
  return sdk.document.application.isApplicationInstalled(
    SCHEDULING_FORM_FIELDS_APP_DEF_ID,
    {
      appDefinitionId: SCHEDULING_FORM_FIELDS_APP_DEF_ID,
    },
  );
}

type InstallSchedulingParams = {
  sdk: EditorSDK;
  flowApi: FlowAPI;
};

export async function maybeInstallScheduling(params: InstallSchedulingParams) {
  const {
    flowApi: { httpClient, reportError },
    sdk,
  } = params;

  try {
    const hasFormsWithScheduling = !!(
      await fetchFormsWithScheduling(httpClient)
    )?.length;

    if (hasFormsWithScheduling) {
      const schedulingInstalled = await isSchedulingInstalled(sdk);

      if (!schedulingInstalled) {
        provisionApp(SCHEDULING_FORM_FIELDS_APP_DEF_ID);
      }
    }
  } catch (e: unknown) {
    reportError(e as Error | string);
  }
}

async function fetchFormsWithScheduling(httpClient: IHttpClient) {
  try {
    const response = await httpClient?.request(
      queryForms({
        query: {
          filter: {
            'fields.validation.predefined.format': {
              $hasSome: ValidationFormat.SCHEDULING,
            },
            namespace: { $eq: NAMESPACE },
          },
          cursorPaging: {
            limit: 1,
          },
        },
      }),
    );
    return response.data.forms;
  } catch (e: any) {
    return [];
  }
}
